import {
  type FieldErrorProps,
  Group,
  type GroupProps,
  type InputProps,
  type LabelProps,
  FieldError as RACFieldError,
  Input as RACInput,
  Label as RACLabel,
  Text,
  type TextProps,
  composeRenderProps,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { composeTailwindRenderProps, focusRing, inputBase } from "./utils";

export function Label(props: LabelProps) {
  return (
    <RACLabel
      {...props}
      className={twMerge(
        "text-sm block font-medium pb-[0.5ex] cursor-default w-fit [[data-disabled]_&]:opacity-50",
        props.className
      )}
    />
  );
}

export function Description(props: TextProps) {
  return <Text {...props} slot="description" className={twMerge("text-sm text-gray-600", props.className)} />;
}

export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(props.className, "text-sm text-red-600 forced-colors:text-[Mark]")}
    />
  );
}

export const fieldBorderStyles = tv({
  variants: {
    isFocusWithin: focusRing.variants.isFocusVisible,
    isInvalid: {
      true: "ring-2 ring-red-500",
    },
    isDisabled: {
      true: "border-gray-200 dark:border-zinc-700 opacity-50",
    },
  },
});

export const fieldGroupStyles = tv({
  extend: focusRing,
  base: "group flex items-center h-9 overflow-hidden bg-white border rounded-lg",
  variants: fieldBorderStyles.variants,
});

export function FieldGroup(props: GroupProps) {
  return (
    <Group
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({ ...renderProps, className })
      )}
    />
  );
}

export function Input(props: InputProps) {
  return (
    <RACInput
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "px-2 py-1.5 flex-1 min-w-0 outline outline-0 bg-white text-gray-950 disabled:text-gray-200"
      )}
    />
  );
}
